// Packages
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';

// Layouts
import MainLayout from "../../layouts/MainLayout";

// Utils
import { IconBarChartGraph, IconSpin } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";

// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_GENERAL_STATUS, API_URL_AUTH_ME } from "../../core/Constants/API_URL";

// Components
import { Button } from "../../components";
import { shallowEqual, useSelector } from "react-redux";
const GeneralStatusPage = () => {
    const user = useSelector(state => state.user, shallowEqual);
    const navigate = useNavigate();

    const isProgress = {
        icon: "animate-spin",
        text: "ml-8",
        wrapper: "flex items-center"
    }

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [recordCount, setRecordCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const fetchData = async (page) => {
        const responseData = await query.requestAsync({
            request: {
                method: "get",
                url: API_URL_GENERAL_STATUS.replace(":page", page)
            }
        });

        setLoading(false);
        setPage(page);
        if (!responseData.isError && responseData.length > 0) {
            console.log(responseData);
            setPageCount(responseData[0].pageCount);
            setRecordCount(responseData[0].recordCount);
            setDataSource(responseData);
        }
    }

    useEffect(() => {
        fetchData(page).then(p => p);
    }, [])


    const prev = async () => {
        await fetchData(page - 1);
    }

    const next = async () => {
        await fetchData(page + 1);
    }

    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white">
                    <IconBarChartGraph className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <h1 className="text-24 font-bold text-gray-800">Koordinasyon/Dayanışma Ekranı</h1>
                </div>

                {
                    loading ? <div className="p-16 text-20 bg-white text-center">
                        <IconSpin
                            className={isProgress.icon}
                            width={24}
                        />

                        Yükleniyor...
                    </div> :
                        (
                            recordCount === 0
                                ? <div className="p-16 text-14 bg-white">
                                    {
                                        user.personType === 2 ?
                                            <div className="pb-16 text-14 bg-white">
                                                <Button
                                                    className="ml-auto"
                                                    text="Yeni Kayıt"
                                                    variant="primary"
                                                    size="small"
                                                    onClick={() => navigate('/general-status-form/0')}
                                                />
                                            </div> :
                                            <></>
                                    }
                                    Şu anda bekleyen bir destek talebi bulunmamaktadır.
                                </div>
                                :
                                <div className="p-16 text-14 bg-white">
                                    {
                                        user.personType === 2 ?
                                            <div className="pb-16 text-14 bg-white">
                                                <Button
                                                    className="ml-auto"
                                                    text="Yeni Kayıt"
                                                    variant="primary"
                                                    size="small"
                                                    onClick={() => navigate('/general-status-form/0')}
                                                />
                                            </div> :
                                            <></>
                                    }
                                    <table className="table-with-text border border-collapse">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th width={130} className="border border-solid border-1  text-left px-16 py-16">Tarih</th>
                                                <th className="border border-solid border-1  text-left px-8 py-4">Başlık</th>
                                                <th className="border border-solid border-1  text-left px-8 py-4">Yetkili/Kurum</th>
                                                <th width={200} className="border border-solid border-1  text-left px-8 py-4">İl/İlçe</th>
                                                <th className="border border-solid border-1  text-left px-8 py-4">İhtiyaç Özeti</th>
                                                <th width={150} className="border border-solid border-1  text-left px-8 py-4">Durum</th>
                                                {
                                                    user.personType === 2 ?
                                                        <th width={100} className="text-center border border-solid border-1  text-left px-8 py-4">Düzenle</th>
                                                        : <></>
                                                }
                                                <th width={100} className="text-center border border-solid border-1  text-left px-8 py-4">Detay</th>
                                            </tr>
                                        </thead>
                                        <tbody>{
                                            _.map(dataSource, (item, index) => {
                                                return (
                                                    <tr key={`item_${index}`}>
                                                        <td data-title="Tarih" className="border border-solid border-1  text-left px-8 py-4">
                                                            {Moment(item.cDate).format('DD.MM.YYYY HH:mm')}
                                                        </td>
                                                        <td data-title="Başlık" className="border border-solid border-1  text-left px-8 py-4">
                                                            {item.title}
                                                        </td>
                                                        <td data-title="Yetkili/Kurum" className="border border-solid border-1  text-left px-8 py-4">
                                                            {item.name}
                                                            <br />
                                                            <small>{item.corporationName}</small>
                                                        </td>
                                                        <td data-title="İl" className="border border-solid border-1 text-left px-8 py-4">
                                                            {item.city}/ {item.town}
                                                        </td>
                                                        <td data-title="İhtiyaç Özeti" className="border border-solid border-1  text-left px-8 py-4">
                                                            {item.summary}
                                                            <br />
                                                            <small>{item.importanceLevel === 1 ? "Kritik" : "Normal"}</small>
                                                        </td>
                                                        <td data-title="Durum" className="border border-solid border-1 font-bold text-left px-8 py-4">

                                                            {
                                                                item.status === 1 ? <span className="text-danger">Destek Bekleniyor</span> :
                                                                    (
                                                                        item.status === 2 ? <span className="text-info">Koordine Edildi</span> :
                                                                            (item.status === 3) ? <span className="text-success">Tamamlandı</span> :
                                                                                "-"
                                                                    )
                                                            }
                                                        </td>

                                                        {
                                                            user.personType === 2 ?
                                                                <td className="border border-solid border-1  text-left px-8 py-4">
                                                                    <Button
                                                                        className="ml-auto"
                                                                        text="Düzenle"
                                                                        variant="primary"
                                                                        size="medium"
                                                                        onClick={() => navigate('/general-status-form/' + item.id)}
                                                                    /></td> :
                                                                <></>
                                                        }

                                                        <td className="border border-solid border-1 text-left px-8 py-4">
                                                            <Button
                                                                className="ml-auto"
                                                                text="Detaya Git >"
                                                                variant="primary"
                                                                size="medium"
                                                                onClick={() => navigate('/general-status-detail/' + item.id)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>


                                    <div className="flex justify-between mt-16">
                                        {
                                            page > 1
                                                ? <Button
                                                    isProgress={query.isAjax}
                                                    className="flex-6"
                                                    onClick={() => prev()}
                                                    text="< Önceki Sayfa"
                                                    variant="primary"
                                                />
                                                : <></>
                                        }

                                        {
                                            page < pageCount
                                                ?

                                                <Button
                                                    isProgress={query.isAjax}
                                                    className="ml-auto flex-6"
                                                    onClick={() => next()}
                                                    text="Sonraki Sayfa >"
                                                    variant="primary"
                                                />
                                                : <></>
                                        }
                                    </div>
                                </div>
                        )
                }



            </div>
        </MainLayout>
    );
}

export default GeneralStatusPage;
