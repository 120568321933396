export const API_URL_BASE =  "https://apitest.afet.vet/api";

export const API_URL_CITIES = "/common/cities";
export const API_URL_TOWNS_BY_CITY_ID = "/common/towns/:id";
export const API_URL_CATEGORIES = "/common/categories";
export const API_URL_CREATE_SUPPORT = "/support-request/add";
export const API_URL_CREATE_SUPPORTER = "/supporter/add";

export const API_URL_LOGIN = "/auth/login";
export const API_URL_REGISTER = "/auth/register";
export const API_URL_AUTH_ME = "/auth/me";
export const API_URL_HAS_USER = "/auth/has-user";
export const API_URL_SEND_VALIDATION_CODE = "/auth/send-validation-code";
export const API_URL_USER_VALIDATE = "/auth/validate";

export const API_URL_GENERAL_STATUS = "/current-status/list/:page";
export const API_URL_GENERAL_STATUS_BY_ID = "/current-status/:id";
export const API_URL_GENERAL_STATUS_SUPPORTING_BY_ID = "/current-status/supporting-details/:id";
export const API_URL_GENERAL_STATUS_SAVE = "/current-status/save";
export const API_URL_GIVE_SUPPORT = "/current-status/give-support";
