// Packages
import React from "react";
import {useNavigate} from "react-router-dom";
// Layouts
import MainLayout from "../../layouts/MainLayout";
// Utils
import {IconBarChartGraph, IconGiveLoveHand, IconLifeGuard, IconLogo, IconNavigationTable} from "../../utils/icons";
// Constants
import {PAGE_URL_GENERAL_STATUS, PAGE_URL_GET_SUPPORT, PAGE_URL_PROVIDE_SUPPORT, PAGE_URL_ROADMAP} from "../../core/Constants/PAGE_URL";
// Components
// Styles
import styles from "./styles";
import {useDevice} from "../../utils/hooks";


const HomePage = () => {
    const navigate = useNavigate();
    const {isMobile} = useDevice();

    return (
        <MainLayout
            contentClassName="flex md:items-center"
        >
            <div className="container">
                <div className="text-center mb-24 md:mb-50">
                    <IconLogo width={isMobile ? 160 : 240}/>
                    <div className="mt-12 font-bold text-20 md:text-28 leading-none">
                        <p>VETERİNER HEKİM</p>
                        <p>DAYANIŞMA VE KOORDİNASYON</p>
                        <p>PLATFORMU</p>
                    </div>
                </div>

                <div className="text-16 font-medium -mx-8 md:flex">
                    <div className={styles.supportButtons} onClick={() => navigate(PAGE_URL_GET_SUPPORT)}>
                        <IconLifeGuard height={32}/>
                        <div className="ml-8">Destek İste</div>
                    </div>

                    <div className={styles.supportButtons} onClick={() => navigate(PAGE_URL_PROVIDE_SUPPORT)}>
                        <IconGiveLoveHand height={36}/>
                        <div className="ml-8">Destek Sağla</div>
                    </div>
                </div>

                <div className="text-16 font-medium -mx-8 md:flex">
                    <div className={styles.supportButtons} onClick={() => navigate(PAGE_URL_GENERAL_STATUS)}>
                        <IconBarChartGraph height={32}/>
                        <div className="ml-8">Koordinasyon/Dayanışma Ekranı</div>
                    </div>

                    <div className={styles.supportButtons} onClick={() => navigate(PAGE_URL_ROADMAP)}>
                        <IconNavigationTable height={32}/>
                        <div className="ml-8">Proje Hakkında</div>
                    </div>
                </div>

                <div className="text-14 text-center mt-20 pb-20 md:mt-50 md:pb-0">
                    <strong>Afet.vet</strong>, veteriner hekimlerin afet ve olağanüstü ihtiyaç durumlarında dayanışma ve koordinasyon sağlanması için oluşturulmuş olan, <strong>TÜRK VETERİNER HEKİMLER BİRLİĞİ</strong>’ne ait bir platformdur.
                </div>
            </div>
        </MainLayout>
    );
}

export default HomePage;
