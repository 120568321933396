// Packages
import React, { useCallback, useEffect } from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
// Layouts
import MainLayout from "../../layouts/MainLayout";
// Utils
import { IconGiveLoveHand, IconNew, IconTrash } from "../../utils/icons";
import useQuery from "../../utils/hooks/useQuery";
// Core
import { AXIOS_INSTANCE } from "../../core/Constants/COMMON";
import { API_URL_CATEGORIES, API_URL_CREATE_SUPPORTER } from "../../core/Constants/API_URL";
import { VALIDATION_SCHEMA_GIVE_SUPPORT } from "../../core/Constants/VALIDATION_SCHEMA";
// Components
import { Button, Checkbox, Input, Select } from "../../components";
// Styles
import styles from "./styles";
import { StringHelper } from "../../core/Helpers";


const ProvideSupportPage = () => {
    const navigate = useNavigate();

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const formik = useFormik({
        initialValues: {
            _categories: [],
            categories: [],
            description: "",
            isValid: false,
            amount: 0
        },
        onSubmit: async data => {
            let _data = {
                isValid: data.isValid,
                categories: data.categories,
                description: data.description,
                amount: data.amount,
                supporterVets: []
            }

            _.forEach(data._categories, mainItem => {
                _.forEach(mainItem.subItem, subItem => {
                    if (subItem.name) {
                        _data.supporterVets.push({
                            ...subItem
                        })
                    }
                })
            });

            if (_data.categories.length === 0 && _data.supporterVets.length === 0) {
                alert("En az 1 adet sağlayabileceğiniz hizmeti giriniz!")
            } else if (_data.categories.indexOf(10) > -1 && _data.amount === 0) {
                alert("Lütfen maddi destek tutarını giriniz!");
            } else if (_data.categories.indexOf(1) > -1 && (_data.supporterVets.length === 0 || _data.supporterVets.filter(t => t.name === "").length > 0)) {
                alert("Lütfen en az 1 adet veteriner hekim / desktek personeli bilgisi giriniz!");
            }
            else if (_data.supporterVets.filter(t => t.gsm.length > 0 && t.gsm.length < 10).length > 0) {
                alert("Lütfen telefon numarasını doğru giriniz!");
            }
            else {
                const result = await query.requestAsync({
                    request: {
                        data: _data,
                        url: API_URL_CREATE_SUPPORTER
                    }
                });

                if (!result.isError) {
                    alert(`DS000${result.idSupporterRequest} no'lu talebiniz Afet.Vet e ulaşmıştır. Sizinle en kısa sürede iletişim sağlanacaktır!`);
                    navigate("/");
                }
            }
        },
        validationSchema: VALIDATION_SCHEMA_GIVE_SUPPORT()
    })

    useEffect(() => {
        const fetchData = async () => {
            const categories = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_CATEGORIES
                }
            });

            if (!categories.isError) {
                await formik.setFieldValue("_categories", _.map(_.orderBy(categories, ["sortNo"]), item => {
                    let result = {
                        ...item
                    }
                    if (item.idCategory === 1) {
                        result.subItem = [
                            {
                                title: "Veteriner Hekim",
                                name: "",
                                gsm: "",
                                description: ""
                            }
                        ]
                    } else if (item.idCategory === 10) {
                        result.amount = 0;
                    }

                    return result;
                }));

            }
        }

        fetchData().then(p => p);
    }, []);

    const onAddVeterinarianItem = useCallback(async () => {
        let datas = formik.values._categories;
        const index = _.findIndex(datas, o => o.idCategory === 1);

        datas[index].subItem.push({
            title: "",
            name: "",
            gsm: "",
            description: ""
        });

        await formik.setFieldValue("_categories", datas);
    }, [formik.values._categories]);

    const onChangeVeterinarian = useCallback(async (value, name, subIndex) => {
        let datas = formik.values._categories;
        const index = _.findIndex(datas, o => o.idCategory === 1);

        datas[index].subItem[subIndex] = {
            ...datas[index].subItem[subIndex],
            [name]: name === "gsm" ? StringHelper.generatePhoneFromMask(value) : value
        }

        await formik.setFieldValue("_categories", datas);
    }, [JSON.stringify(formik.values._categories)]);

    const onRemoveVeterinarianItem = useCallback(async (itemIndex) => {
        let datas = formik.values._categories;
        const index = _.findIndex(datas, o => o.idCategory === 1);

        datas[index].subItem.splice(itemIndex, 1);

        await formik.setFieldValue("_categories", datas);
    }, [formik.values._categories]);

    const onToggleOtherCategories = useCallback(async (idCategory) => {
        let datas = formik.values.categories;

        if (datas.indexOf(idCategory) === -1) {
            datas.push(idCategory);
        } else {
            datas = _.filter(datas, o => o !== idCategory);
        }

        await formik.setFieldValue("categories", datas);
    }, [JSON.stringify(formik.values.categories)]);

    return (
        <MainLayout>
            <div className="container">
                <div className="py-16 mb-16 pl-16 relative overflow-hidden bg-white">
                    <IconGiveLoveHand className="absolute -right-16 -top-16 opacity-50" height={148} />
                    <h1 className="text-24 font-bold text-gray-800">Destek Sağla</h1>
                    <div className="block text-12 text-gray-500">İhtiyaç halinde hangi hizmet/ürün gruplarına destek sağlayabilirsin?</div>
                </div>

                <div className="p-16 bg-white">

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <h1 className="hidden text-14 font-bold">Sağlanabilecek Hizmetler</h1>

                            {_.map(formik.values._categories, (item, index) => {
                                return (
                                    <div key={`checkbox_${index}`} className="mb-8">
                                        <Checkbox
                                            labelClassName="text-13"
                                            label={item.name}
                                            checked={formik.values.categories.indexOf(item.idCategory) > -1}
                                            onChange={checked => onToggleOtherCategories(item.idCategory)}
                                        />

                                        {
                                            item.idCategory === 10
                                                ? (
                                                    formik.values.categories.indexOf(10) > -1
                                                        ? (
                                                            <div className="flex items-end bg-gray-200 p-16 mt-8 mb-16">
                                                                <div className="flex-1">
                                                                    <Input
                                                                        label={item.description}
                                                                        onChange={({ target: { value } }) => formik.setFieldValue("amount", parseFloat(value))}
                                                                        type="number"
                                                                        value={formik.values.amount}
                                                                        min={0}
                                                                        maxLength="20"
                                                                    />
                                                                </div>
                                                                <div className="text-15 pl-10 relative bottom-8">{item.units}</div>
                                                            </div>
                                                        )
                                                        : null
                                                )
                                                : (
                                                    item.idCategory === 1
                                                        ? (
                                                            formik.values.categories.indexOf(1) > -1
                                                                ? (
                                                                    <div className="bg-gray-200 p-16 mt-8 mb-16">
                                                                        <table cellPadding={4} className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="center" width={32}></th>
                                                                                    <th width={270}>Ünvan</th>
                                                                                    <th>Ad Soyad</th>
                                                                                    <th>Telefon</th>
                                                                                    <th>Açıklama</th>
                                                                                    <th width={32}></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    _.map(item.subItem, (mainItem, mainIndex) => {
                                                                                        return (
                                                                                            <tr key={`item_${mainIndex}`}>
                                                                                                <td className="text-14 font-medium text-center">{mainIndex + 1}</td>
                                                                                                <td>
                                                                                                    <Select
                                                                                                        items={[
                                                                                                            { value: "Veteriner Hekim", label: "Veteriner Hekim" },
                                                                                                            { value: "Veteriner Teknikeri / Teknisyeni", label: "Veteriner Teknikeri / Teknisyeni" },
                                                                                                            { value: "Yardımcı Personel", label: "Yardımcı Personel" },
                                                                                                        ]}
                                                                                                        onChange={({ target: { value } }) => onChangeVeterinarian(value, "title", mainIndex)}
                                                                                                        value={mainItem?.title}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <Input
                                                                                                        onChange={({ target: { value } }) => onChangeVeterinarian(value, "name", mainIndex)}
                                                                                                        placeholder="Adı Soyadı"
                                                                                                        value={mainItem?.name}
                                                                                                        maxLength="100"
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <Input
                                                                                                        onChange={({ target: { value } }) => onChangeVeterinarian(value, "gsm", mainIndex)}
                                                                                                        placeholder="Telefon"
                                                                                                        type="gsm"
                                                                                                        value={mainItem?.gsm}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <Input
                                                                                                        onChange={({ target: { value } }) => onChangeVeterinarian(value, "description", mainIndex)}
                                                                                                        placeholder="Açıklama"
                                                                                                        value={mainItem?.description}
                                                                                                        maxLength="500"
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        mainIndex !== 0 && (
                                                                                                            <div
                                                                                                                className="text-red-500 cursor-pointer text-center"
                                                                                                                onClick={() => onRemoveVeterinarianItem(mainIndex)}
                                                                                                            >
                                                                                                                <IconTrash width={26} className="text-red-500" />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>

                                                                        <Button
                                                                            className="mt-6"
                                                                            icon={<IconNew width={16} />}
                                                                            onClick={onAddVeterinarianItem}
                                                                            text="Satır Ekle"
                                                                            variant="default"
                                                                        />
                                                                    </div>
                                                                )
                                                                : null
                                                        )
                                                        : null
                                                )
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Input
                                label="Açıklama"
                                multiline
                                onChange={({ target: { value } }) => formik.setFieldValue("description", value)}
                                rows={4}
                                value={formik.values.description}
                                maxLength="2000"
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Checkbox
                                labelClassName="ml-7 text-13"
                                errorMessage={formik.touched.isValid && formik.errors.isValid}
                                onChange={value => formik.setFieldValue("isValid", value)}
                                checked={formik.values.isValid}
                                label="Girdiğim bilgilerin doğru olduğunu onaylıyorum!"
                            />
                        </div>
                    </div>

                    <Button
                        isProgress={query.isAjax}
                        className="ml-auto"
                        onClick={formik.submitForm}
                        text="Destek Sağlama Talebini Gönder"
                        variant="primary"
                    />
                </div>
            </div>
        </MainLayout>
    );
}

export default ProvideSupportPage;
