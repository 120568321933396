// Packages
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import _ from "lodash";
import { useDispatch } from "react-redux";
// Core
import { LocaleHelper, StorageHelper, StringHelper } from "../../../core/Helpers";
import { AXIOS_INSTANCE } from "../../../core/Constants/COMMON";
import { API_URL_AUTH_ME, API_URL_CITIES, API_URL_LOGIN, API_URL_HAS_USER, API_URL_SEND_VALIDATION_CODE, API_URL_USER_VALIDATE, API_URL_REGISTER, API_URL_TOWNS_BY_CITY_ID } from "../../../core/Constants/API_URL";
import { VALIDATION_SCHEMA_REGISTER, VALIDATION_SCHEMA_SIGN_IN, VALIDATION_SCHEMA_SMS_CODE } from "../../../core/Constants/VALIDATION_SCHEMA";
import { SET_USER } from "../../../redux/actions/userActions";
// Components
import { Button, Checkbox, Input, Select } from "../../../components";
// Utils
import useQuery from "../../../utils/hooks/useQuery";
import { useDevice } from "../../../utils/hooks";
import { IconClose, IconLogo } from "../../../utils/icons";


const LoginOrRegisterModal = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isMobile } = useDevice();
    LocaleHelper.setLocale();

    const query = useQuery({
        axiosInstance: AXIOS_INSTANCE()
    });

    const formikSignIn = useFormik({
        initialValues: {
            gsm: "",
        },
        onSubmit: async data => {
            const hasUserRequest = await query.requestAsync({
                request: {
                    data: {
                        gsm: StringHelper.generatePhoneFromMask(data?.gsm)
                    },
                    url: API_URL_HAS_USER
                }
            });

            if (!hasUserRequest.isError) {
                await formikSmsValidation.setFieldValue("gsm", data.gsm);
                await formikSmsValidation.setFieldValue("_forSignIn", true);
                await formikSmsValidation.setFieldValue("_showModal", true);
            } else {
                formikSignIn.resetForm({});
                alert("Giriş işleminiz başarısız! Yeni kayıt oluşturabilir veya farklı bir numara deneyebilirsiniz!");
            }
        },
        validationSchema: VALIDATION_SCHEMA_SIGN_IN()
    });

    const formikRegister = useFormik({
        initialValues: {
            _cities: [],
            _towns: [],
            name: "",
            corporationName: "",
            gsm: "",
            emailAddress: "",
            idCity: 0,
            idTown: 0,
            address: "",
            location: "",
            veterinarianConfirm: false,
            gdprConfirm: false
        },
        onSubmit: async data => {
            await formikSmsValidation.setFieldValue("gsm", data.gsm);
            await formikSmsValidation.setFieldValue("_forSignIn", false);
            await formikSmsValidation.setFieldValue("_showModal", true);
        },
        validationSchema: VALIDATION_SCHEMA_REGISTER()
    });

    const formikSmsValidation = useFormik({
        initialValues: {
            _forSignIn: true,
            _showModal: false,
            gsm: "",
            smsCode: ""
        },
        onSubmit: async data => {
            const validationResult = await query.requestAsync({
                request: {
                    data: {
                        gsm: StringHelper.generatePhoneFromMask(data.gsm),
                        code: data.smsCode
                    },
                    url: API_URL_USER_VALIDATE
                }
            });

            if (validationResult.isError) {
                await formikSmsValidation.setFieldValue("smsCode", "");
                alert("SMS kodunu hatalı girdiniz! Lütfen kontrol edip tekrar giriniz!");
            } else {
                if (data._forSignIn) {
                    const result = await query.requestAsync({
                        request: {
                            data: {
                                ...data,
                                gsm: StringHelper.generatePhoneFromMask(data.gsm)
                            },
                            url: API_URL_LOGIN
                        }
                    });

                    if (!result.isError) {
                        setShowModal(false);
                        StorageHelper.setAuthTokens({ ...result });
                        await formikSmsValidation.setFieldValue("_showModal", false);
                        const resultMe = await query.requestAsync({
                            request: {
                                method: "get",
                                url: API_URL_AUTH_ME
                            }
                        });

                        if (!resultMe.isError) {
                            dispatch({ type: SET_USER, payload: resultMe });
                        }

                    }
                } else {
                    const result = await query.requestAsync({
                        request: {
                            data: {
                                ...formikRegister.values,
                                gsm: StringHelper.generatePhoneFromMask(formikRegister.values.gsm)
                            },
                            url: API_URL_REGISTER
                        }
                    });

                    if (!result.isError) {
                        setShowModal(false);
                        StorageHelper.setAuthTokens({ ...result });
                        await formikSmsValidation.setFieldValue("_showModal", false);

                        const resultMe = await query.requestAsync({
                            request: {
                                method: "get",
                                url: API_URL_AUTH_ME
                            }
                        });

                        if (!resultMe.isError) {
                            dispatch({ type: SET_USER, payload: resultMe });
                        }
                    }
                }
            }
        },
        validationSchema: VALIDATION_SCHEMA_SMS_CODE()
    });

    useEffect(() => {
        const token = StorageHelper.getAuthTokens().token;

        const fetchAuthMeData = async () => {
            const result = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_AUTH_ME
                }
            });

            if (!result.isError) {
                dispatch({ type: SET_USER, payload: result });
            }

            result.isError && setShowModal(true);
        }

        if (token) {
            fetchAuthMeData().then(p => p);
        } else {
            setShowModal(true);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // if (navigator.geolocation) {
            //     navigator.geolocation.getCurrentPosition(async position => {
            //         await formikRegister.setFieldValue("location", `${position.coords.latitude},${position.coords.longitude}`);
            //     });
            // }

            const cities = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_CITIES
                }
            });

            if (!cities.isError && cities.length > 0) {
                await formikRegister.setFieldValue("_cities", _.map(cities, item => {
                    return {
                        value: item.idCity,
                        label: item.name
                    }
                }));
                await formikRegister.setFieldValue("idCity", cities[0].idCity);
            }
        }

        showModal && fetchData().then(p => p);
    }, [showModal]);

    useEffect(() => {
        const fetchData = async () => {
            const towns = await query.requestAsync({
                request: {
                    method: "get",
                    url: API_URL_TOWNS_BY_CITY_ID.replace(":id", formikRegister.values.idCity)
                }
            });

            if (!towns.isError && towns.length > 0) {
                await formikRegister.setFieldValue("_towns", _.map(towns, item => {
                    return {
                        value: item.idTown,
                        label: item.name
                    }
                }));

                await formikRegister.setFieldValue("idTown", towns[0].idTown);
            }
        }

        formikRegister.values.idCity && fetchData().then(p => p);
    }, [formikRegister.values.idCity]);

    const modalStyle = useMemo(() => {
        return {
            content: {
                position: "absolute",
                backgroundColor: "#FFF",
                padding: "16px",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "100%",
                height: "100%",
                maxHeight: isMobile ? "100%" : "556px",
                maxWidth: "720px",
                borderRadius: "0",

            },
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                overflowY: "auto",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "100%",
            },
        }
    }, []);

    return (
        <>
            <Modal
                isOpen={showModal}
                ariaHideApp={false}
                style={modalStyle}
            >
                <div className="flex flex-col h-full md:flex-row md:h-auto">
                    <div className="border-b-1 -mx-16 px-16 pb-16 md:hidden">
                        <div className="flex justify-between">
                            <IconLogo
                                className="cursor-pointer"
                                height={40}
                            />

                            <img className="h-48" src="/assets/tvhb_logo.png" alt="Türk Veteriner Hekimleri Birliği" />
                        </div>
                    </div>

                    <div className="flex-1 overflow-y-auto pt-12 md:flex">
                        <div className="md:border-r-1 md:pr-16 min-w-320">


                            <div className="text-center font-bold text-20 mb-16">Giriş Yap</div>

                            <Input
                                errorMessage={formikSignIn.touched.gsm && formikSignIn.errors.gsm}
                                placeholder="Telefon"
                                onChange={({ target: { value } }) => formikSignIn.setFieldValue("gsm", value)}
                                type="gsm"
                                value={formikSignIn.values.gsm}
                            />

                            <Button
                                block
                                className="mt-8"
                                isProgress={query.isAjax}
                                onClick={formikSignIn.submitForm}
                                type="submit"
                                text="Giriş Yap"
                            />
                        </div>
                        <div className="md:pl-16 min-w-320 mt-16 md:mt-0">
                            <div className="text-center font-bold text-20 mb-16">Kayıt Ol</div>
                            <div className="mb-8">
                                <Input
                                    errorMessage={formikRegister.touched.name && formikRegister.errors.name}
                                    onChange={({ target: { value } }) => formikRegister.setFieldValue("name", value)}
                                    placeholder="Adı Soyadı*"
                                    value={formikRegister.values.name}
                                    maxLength="100"
                                />
                            </div>
                            <div className="mb-8">
                                <Input
                                    errorMessage={formikRegister.touched.corporationName && formikRegister.errors.corporationName}
                                    onChange={({ target: { value } }) => formikRegister.setFieldValue("corporationName", value)}
                                    placeholder="Kurum / Klinik / Firma Adı*"
                                    value={formikRegister.values.corporationName}
                                    maxLength="300"
                                />
                            </div>
                            <div className="mb-8">
                                <Input
                                    errorMessage={formikRegister.touched.gsm && formikRegister.errors.gsm}
                                    onChange={({ target: { value } }) => formikRegister.setFieldValue("gsm", value)}
                                    placeholder="Telefon*"
                                    type="gsm"
                                    value={formikRegister.values.gsm}
                                />
                            </div>
                            <div className="mb-8">
                                <Input
                                    errorMessage={formikRegister.touched.emailAddress && formikRegister.errors.emailAddress}
                                    onChange={({ target: { value } }) => formikRegister.setFieldValue("emailAddress", value)}
                                    placeholder="Email Adres"
                                    value={formikRegister.values.emailAddress}
                                    maxLength="100"
                                />
                            </div>

                            <div className="mb-8">
                                <Select
                                    items={formikRegister.values._cities}
                                    placeholder="İl seçiniz"
                                    onChange={({ target: { value } }) => formikRegister.setFieldValue("idCity", value)}
                                    value={formikRegister.values.idCity}
                                />
                            </div>
                            <div className="mb-8">
                                <Select
                                    items={formikRegister.values._towns}
                                    placeholder="İlçe seçiniz"
                                    onChange={({ target: { value } }) => formikRegister.setFieldValue("idTown", value)}
                                    value={formikRegister.values.idTown}
                                />
                            </div>
                            <div className="mb-8">
                                <Input
                                    errorMessage={formikRegister.touched.address && formikRegister.errors.address}
                                    multiline
                                    onChange={({ target: { value } }) => formikRegister.setFieldValue("address", value)}
                                    placeholder="Adres*"
                                    rows={3}
                                    value={formikRegister.values.address}
                                    maxLength="500"
                                />
                            </div>

                            <Checkbox
                                labelClassName="ml-7 text-13"
                                errorMessage={formikRegister.touched.veterinarianConfirm && formikRegister.errors.veterinarianConfirm}
                                onChange={value => formikRegister.setFieldValue("veterinarianConfirm", value)}
                                checked={formikRegister.values.veterinarianConfirm}
                                label="Veteriner hekim olduğumu onaylıyorum!"
                            />

                            <div className="my-8">
                                <Checkbox
                                    labelClassName="ml-7 text-13"
                                    errorMessage={formikRegister.touched.gdprConfirm && formikRegister.errors.gdprConfirm}
                                    onChange={value => formikRegister.setFieldValue("gdprConfirm", value)}
                                    checked={formikRegister.values.gdprConfirm}
                                    label={<><a className="underline font-medium" target="_blank" href="/gdpr">KVKK Aydınlatma metnini</a> okudum, onaylıyorum!</>}
                                />
                            </div>

                            <Button
                                block
                                className="mt-8"
                                isProgress={query.isAjax}
                                onClick={formikRegister.submitForm}
                                type="submit"
                                text="Kayıt Ol"
                            />
                        </div>
                    </div>

                    <div className="text-13 border-t-1 pt-12 md:hidden">
                        <strong>Afet.vet</strong>, veteriner hekimlerin afet ve olağanüstü ihtiyaç durumlarında dayanışma ve koordinasyon sağlanması için oluşturulmuş olan, <strong>TÜRK VETERİNER HEKİMLER BİRLİĞİ</strong>’ne ait bir platformdur.
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={formikSmsValidation.values._showModal}
                ariaHideApp={false}
                onHide={() => formikSmsValidation.setFieldValue("_showModal", false)}
                style={{
                    ...modalStyle,
                    content: {
                        ...modalStyle.content,
                        maxHeight: '240px',
                        maxWidth: '360px'
                    }
                }}
            >
                <div className="flex items-center justify-between -mt-16 -mx-16 border-b-1">
                    <div className="font-bold text-16 pl-16">SMS Doğrulama Kodu</div>
                    <div
                        className="p-16 cursor-pointer"
                        onClick={() => formikSmsValidation.setFieldValue("_showModal", false)}
                    >
                        <IconClose width={20} />
                    </div>
                </div>
                <div className="pt-16 text-center">
                    <div className="text-13 mb-32 mt-16 text-gray-700">Lütfen telefonunuza gelen SMS kodunu giriniz!</div>

                    <Input
                        className="text-center"
                        errorMessage={formikSmsValidation.touched.smsCode && formikSmsValidation.errors.smsCode}
                        onChange={({ target: { value } }) => formikSmsValidation.setFieldValue("smsCode", value)}
                        placeholder="SMS Kodu"
                        type="number"
                        value={formikSmsValidation.values.smsCode}
                    />

                    <Button
                        block
                        className="mt-8"
                        isProgress={query.isAjax}
                        onClick={formikSmsValidation.submitForm}
                        text="Kodu Doğrula"
                    />
                </div>
            </Modal>
        </>
    )
};

export default LoginOrRegisterModal;
