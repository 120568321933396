// Packages
import React from "react";
import { Route, Routes } from "react-router-dom";
// Pages
import { GetSupportPage, ProvideSupportPage, Gdpr, HomePage, GeneralStatusPage, RoadmapPage } from "../pages";
import GeneralStatusEditPage from "../pages/GeneralStatusEditPage";
import GeneralStatusDetailPage from "../pages/GeneralStatusDetailPage";
// Constants
import { PAGE_URL_GDPR, PAGE_URL_GENERAL_STATUS, PAGE_URL_GENERAL_STATUS_DETAIL, PAGE_URL_GET_SUPPORT, PAGE_URL_PROVIDE_SUPPORT, PAGE_URL_ROADMAP, PAGE_URL_GENERAL_STATUS_EDIT } from "./Constants/PAGE_URL";


const Routing = () => {
    return (
        <Routes>
            <Route element={<GetSupportPage />} path={PAGE_URL_GET_SUPPORT} />
            <Route element={<ProvideSupportPage />} path={PAGE_URL_PROVIDE_SUPPORT} />
            <Route element={<GeneralStatusPage />} path={PAGE_URL_GENERAL_STATUS} />
            <Route element={<RoadmapPage />} path={PAGE_URL_ROADMAP} />
            <Route element={<Gdpr />} path={PAGE_URL_GDPR} />
            <Route element={<GeneralStatusDetailPage />} path={PAGE_URL_GENERAL_STATUS_DETAIL} />
            <Route element={<GeneralStatusEditPage />} path={PAGE_URL_GENERAL_STATUS_EDIT} />
            <Route element={<HomePage />} path="/" />
        </Routes>
    )
}

export default Routing;
